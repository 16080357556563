<template>
	<div class="text-right">
		<button
			:class="buttonClasses"
			:disabled="disabled"
			data-cy="button-round"
			class="button-round p-4 w-20 h-20 m-4 pointer-events-auto"
			@click="click"
		>
			<HokIcon :name="iconName" color="white" :size="12" />
		</button>
	</div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'ButtonRound',
	emits: ['click'],
	data() {
		return {
			processing: false
		};
	},
	computed: {
		iconName(): string {
			if (this.checkmark) {
				return 'icon:checkmark';
			}
			if (this.add) {
				return 'icon:add';
			}
			return 'icon:arrow-right';
		},
		buttonClasses(): string[] {
			let buttonClasses = ['bg-color-main'];
			switch (this.styling) {
				case 'b2c':
					buttonClasses = ['bg-color-main'];
					break;
				case 'b2b':
					buttonClasses = ['bg-color-main-business'];
					break;
				case 'disabled':
					buttonClasses = ['bg-color-grey-light'];
					break;
				default:
					break;
			}
			if (this.$isMobile.any) {
				buttonClasses.push('bottom-0 right-0 fixed mr-4');
			}

			return buttonClasses;
		},
		disabled(): boolean {
			return this.styling === 'disabled' || this.processing;
		}
	},
	methods: {
		click($event) {
			if (!this.processing) {
				this.processing = true;
				this.$emit('click', $event);
				setTimeout(() => {
					this.processing = false;
				}, 1000); // allow another click only afer 1 second pause
			}
		}
	},
	props: {
		styling: {
			type: String,
			default: 'b2c',
			validator: (value: string) => ['b2c', 'b2b', 'disabled'].includes(value)
		},
		checkmark: { type: Boolean, default: false },
		add: { type: Boolean, default: false }
	}
});
</script>

<style lang="scss" scoped>
@use 'sass:color';

.button-round {
	transition: all 600ms ease;
	//background-color: $color-yellow;
	border-radius: 50%;
	box-shadow: 0 7px 28px -1px rgba(0, 0, 0, 0.17);
	z-index: 998;

	&.bg-color-yellow {
		&:hover,
		&:active {
			box-shadow:
				0 0 10px white inset,
				0 1px 0 whitesmoke;
		}

		&:active {
			background: color.adjust(#fdd408, $lightness: -10%);
		}
	}
}
</style>
